export const cities = [
  "București",
  "Cluj-Napoca", 
  "Timișoara",
  "Iași",
  "Constanța",
  "Craiova",
  "Brașov",
  "Galați",
  "Ploiești",
  "Oradea",
  "Brăila",
  "Arad",
  "Pitești",
  "Sibiu",
  "Bacău",
  "Târgu Mureș",
  "Baia Mare",
  "Buzău",
  "Botoșani",
  "Satu Mare",
  "Suceava",
  "Piatra Neamț",
  "Drobeta-Turnu Severin",
  "Târgu Jiu",
  "Târgoviște",
  "Focșani",
  "Bistrița",
  "Reșița",
  "Tulcea",
  "Călărași",
  "Giurgiu",
  "Alba Iulia",
  "Deva",
  "Hunedoara",
  "Zalău",
  "Sfântu Gheorghe",
  "Alexandria",
  "Slobozia",
  "Vaslui",
  "Mediaș",
  "Roman",
  "Turda",
  "Râmnicu Vâlcea",
  "Slatina",
  "Miercurea Ciuc"
]

// Orașele principale pentru secțiunea de pe homepage
export const mainCities = [
  {
    name: "București",
    description: "Găsește cei mai buni meseriași și profesioniști în București pentru renovări, reparații și amenajări. Servicii de calitate, prețuri competitive și expertiză locală garantată."
  },
  {
    name: "Cluj-Napoca",
    description: "Servicii profesionale de renovare și reparații în Cluj-Napoca. Meseriași verificați, experiență dovedită și satisfacție garantată pentru toate lucrările tale."
  },
  {
    name: "Timișoara",
    description: "Conectăm clienții din Timișoara cu cei mai buni meseriași locali. Servicii complete de amenajări, instalații și reparații la standarde înalte."
  },
  {
    name: "Iași",
    description: "Profesioniști verificați pentru orice tip de lucrare în Iași. De la renovări complete la reparații punctuale, găsești meșteri specializați pentru nevoile tale."
  },
  {
    name: "Constanța",
    description: "Servicii complete de amenajări și reparații în Constanța. Echipe profesioniste, materiale de calitate și rezultate garantate pentru locuința ta."
  },
  {
    name: "Craiova",
    description: "Meseriași cu experiență în Craiova pentru renovări, instalații și reparații. Calitate garantată, prețuri transparente și servicii prompte."
  },
  {
    name: "Brașov",
    description: "Găsește rapid meseriași profesioniști în Brașov pentru orice tip de lucrare. Servicii complete de amenajări și reparații la cele mai înalte standarde."
  },
  {
    name: "Galați",
    description: "Servicii profesionale de renovare și reparații în Galați. Meseriași verificați, oferte personalizate și rezultate care depășesc așteptările."
  },
  {
    name: "Oradea",
    description: "Conectăm locuitorii din Oradea cu cei mai buni meseriași locali. Servicii complete de amenajări și reparații, executate cu profesionalism."
  },
  {
    name: "Brăila",
    description: "Meseriași profesioniști pentru orice tip de lucrare în Brăila. De la renovări complete la reparații de urgență, suntem aici să te ajutăm."
  },
  {
    name: "Arad",
    description: "Servicii complete de amenajări și reparații în Arad. Meseriași verificați, experiență dovedită și satisfacție garantată pentru toate proiectele tale."
  }
]

// Funcții utile pentru manipularea URL-urilor

  export const getCitySlug = (city) => {
    return cities.includes(city) 
      ? city
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '-')
          .replace(/[^a-z0-9-]/g, '')
      : '';
  };
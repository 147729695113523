const serviceCategories = [
  {
    id: 'renovari-interioare',
    name: 'Renovări Interioare',
    description: 'Servicii complete de renovări interioare, inclusiv zugrăvit, tencuială și montaj parchet.',
    subcategories: [
      { id: 'zugravit', name: 'Zugrăvit', description: 'Servicii profesionale de zugrăvit pentru orice tip de încăpere.' },
      { id: 'tencuiala', name: 'Tencuială', description: 'Tencuieli de calitate pentru un finisaj perfect.' },
      { id: 'gresie-faianta', name: 'Montaj gresie și faianță', description: 'Montaj gresie și faianță pentru băi, bucătării și alte spații.' },
      { id: 'parchet', name: 'Montaj parchet', description: 'Montaj parchet din diverse tipuri de lemn pentru un aspect elegant.' },
      { id: 'tamplar', name: 'Tâmplărie', description: 'Servicii de tâmplărie pentru feronerie și uși de interior.' },
      { id: 'rigips', name: 'Montaj rigips', description: 'Montaj rigips pentru tavane și pereți cu finisaje perfecte.' },
      { id: 'izolatie-termica', name: 'Izolație termică', description: 'Izolație termică eficientă pentru economisirea energiei.' },
      { id: 'vopsitorie', name: 'Vopsitorie decorativă', description: 'Vopsitorie decorativă pentru un finisaj estetic și durabil.' },
      { id: 'tavane-false', name: 'Montaj tavane false', description: 'Instalare tavane false pentru un aspect modern și funcțional.' },
      { id: 'amenajari-poduri', name: 'Amenajări poduri', description: 'Amenajări funcționale și estetice ale podurilor din locuințe.' },
      { id: 'montaj-usi', name: 'Montaj uși interioare', description: 'Montaj uși interioare de calitate pentru fiecare încăpere.' },
      { id: 'montaj-ferestre', name: 'Montaj feronerie feronerie', description: 'Instalarea feroneriei pentru feronerie de calitate.' }
    ]
  },
  {
    id: 'instalatii',
    name: 'Instalații',
    description: 'Instalații electrice, sanitare, termice și altele pentru un confort maxim în locuința ta.',
    subcategories: [
      { id: 'electrice', name: 'Instalații electrice', description: 'Instalații electrice de calitate pentru siguranță și performanță.' },
      { id: 'sanitare', name: 'Instalații sanitare', description: 'Instalații sanitare pentru băi și bucătării, cu tehnologie modernă.' },
      { id: 'termice', name: 'Instalații termice', description: 'Instalații termice pentru încălzire eficientă și ecologică.' },
      { id: 'gaz', name: 'Instalații de gaz', description: 'Instalații de gaz sigure și eficiente pentru locuința ta.' },
      { id: 'ventilatie', name: 'Sisteme de ventilație', description: 'Sisteme de ventilație pentru un aer curat și sănătos în interior.' },
      { id: 'aer-conditionat', name: 'Montaj aer condiționat', description: 'Instalare și montaj aer condiționat pentru confortul casei tale.' },
      { id: 'panouri-solare', name: 'Instalare panouri solare', description: 'Instalare panouri solare pentru economisirea energiei.' },
      { id: 'pompe-caldura', name: 'Instalare pompe de căldură', description: 'Pompe de căldură eficiente pentru o încălzire sustenabilă.' },
      { id: 'sisteme-incalzire', name: 'Sisteme de încălzire în pardoseală', description: 'Sisteme de încălzire în pardoseală pentru confort sporit și economii de energie.' },
      { id: 'automatizari', name: 'Automatizări pentru casă', description: 'Sisteme de automatizare pentru o locuință inteligentă și eficientă.' }
    ]
  },
  {
    id: 'constructii',
    name: 'Construcții',
    description: 'Construcții de orice tip, de la fundații la structuri metalice și case din lemn.',
    subcategories: [
      { id: 'zidarie', name: 'Zidărie', description: 'Servicii de zidărie pentru orice tip de construcție, de la locuințe la clădiri comerciale.' },
      { id: 'fundatii', name: 'Fundații', description: 'Fundații solide pentru construcții durabile.' },
      { id: 'acoperisuri', name: 'Acoperișuri', description: 'Instalare acoperișuri sigure și durabile.' },
      { id: 'structuri-metalice', name: 'Structuri metalice', description: 'Construcția structurilor metalice pentru clădiri industriale și comerciale.' },
      { id: 'consolidari', name: 'Consolidări clădiri', description: 'Servicii de consolidare pentru clădiri vechi sau deteriorate.' },
      { id: 'demolari', name: 'Demolări controlate', description: 'Demolări controlate pentru terenuri pregătite pentru construcții noi.' },
      { id: 'case-lemn', name: 'Construcții case din lemn', description: 'Case din lemn construite cu tehnici moderne și durabile.' },
      { id: 'case-pasive', name: 'Construcții case pasive', description: 'Construcția de case pasive eficiente energetic și ecologice.' },
      { id: 'izolatii-hidro', name: 'Izolații hidroizolante', description: 'Izolații eficiente pentru protejarea clădirilor de infiltrațiile de apă.' },
      { id: 'constructii-industriale', name: 'Construcții industriale', description: 'Construcția de clădiri industriale și hale de producție.' }
    ]
  },
  {
    id: 'exterioare',
    name: 'Amenajări Exterioare',
    description: 'Amenajări exterioare pentru a transforma spațiile verzi și a le face mai funcționale.',
    subcategories: [
      { id: 'gradinarit', name: 'Grădinărit', description: 'Servicii complete de grădinărit pentru un spațiu verde bine întreținut.' },
      { id: 'piscine', name: 'Construcție și întreținere piscine', description: 'Construcția și întreținerea piscinelor pentru relaxare și confort.' },
      { id: 'peisagistica', name: 'Amenajare peisagistică', description: 'Amenajare peisagistică pentru grădini și curți.' },
      { id: 'pavaje', name: 'Pavaje și alei', description: 'Montaj pavaje și alei pentru grădini și zone de acces.' },
      { id: 'terase', name: 'Construcție terase', description: 'Construcția de terase pentru un spațiu exterior de relaxare.' },
      { id: 'garduri', name: 'Montaj garduri și porți', description: 'Montaj garduri și porți pentru protecție și confidențialitate.' },
      { id: 'irigatie', name: 'Sisteme de irigație', description: 'Instalare sisteme de irigație eficiente pentru grădini și terenuri agricole.' },
      { id: 'iluminat-exterior', name: 'Iluminat exterior', description: 'Instalare iluminat exterior pentru siguranță și estetică.' },
      { id: 'foisoare', name: 'Construcție foisoare și pergole', description: 'Construcția de foisoare și pergole pentru relaxare în aer liber.' },
      { id: 'drenaj-teren', name: 'Drenaj teren', description: 'Instalare sisteme de drenaj pentru protejarea terenurilor de apă.' }
    ]
  },
  {
    id: 'servicii-specializate',
    name: 'Servicii Specializate',
    description: 'Servicii specializate pentru diverse nevoi, de la proiectare la curățenie profesională.',
    subcategories: [
      { id: 'proiectare', name: 'Servicii de proiectare', description: 'Servicii de proiectare pentru construcții și renovări.' },
      { id: 'consultanta', name: 'Consultanță în construcții', description: 'Consultanță profesională în domeniul construcțiilor și al renovărilor.' },
      { id: 'curatenie', name: 'Curățenie profesională', description: 'Servicii de curățenie pentru case, birouri și locații comerciale.' },
      { id: 'verificare-termografica', name: 'Verificare termografică', description: 'Verificare termografică pentru identificarea pierderilor de căldură.' },
      { id: 'inspecciones', name: 'Inspecții tehnice', description: 'Servicii de inspecție pentru evaluarea stării construcțiilor.' }
    ]
  },
  {
    id: 'finisaje',
    name: 'Finisaje',
    description: 'Servicii de finisaje pentru a da un aspect profesional fiecărui proiect.',
    subcategories: [
      { id: 'zugraveala', name: 'Zugrăveală', description: 'Zugrăveli interioare și exterioare de calitate.' },
      { id: 'tapet', name: 'Aplicare tapet', description: 'Servicii de aplicare tapet pentru un decor elegant.' },
      { id: 'vopsitorii-decorative', name: 'Vopsitorii decorative', description: 'Vopsitorii decorative pentru crearea unui ambient personalizat.' },
      { id: 'placaje-marmura', name: 'Placaje din marmură', description: 'Instalare placaje din marmură pentru un finisaj rafinat.' },
      { id: 'parchet', name: 'Montaj parchet', description: 'Montaj parchet din lemn masiv sau laminat.' }
    ]
  },
  {
    id: 'reparatii-intretinere',
    name: 'Reparații și Întreținere',
    description: 'Servicii de reparații și întreținere pentru case și apartamente.',
    subcategories: [
      { id: 'reparatii-electrice', name: 'Reparații electrice', description: 'Reparații electrice rapide și sigure pentru locuința ta.' },
      { id: 'reparatii-sanitare', name: 'Reparații sanitare', description: 'Reparații sanitare pentru instalații și echipamente defecte.' },
      { id: 'reparatii-structura', name: 'Reparații structurale', description: 'Reparații structurale pentru clădiri și locuințe deteriorate.' },
      { id: 'reparatii-acoperisuri', name: 'Reparații acoperișuri', description: 'Reparații acoperișuri pentru protecția locuinței tale.' },
      { id: 'intretinere-gradina', name: 'Întreținere grădină', description: 'Întreținere periodică a grădinii pentru un aspect mereu îngrijit.' }
    ]
  }
];


const allTrades = serviceCategories.flatMap(category => 
  category.subcategories.map(subcat => subcat.name)
);
console.log(allTrades);

// Adăugăm mapping-ul pentru meserii
const tradesMapping = {
  'instalator': ['instalatii', ['sanitare', 'termice', 'gaz']],
  'electrician': ['instalatii', ['electrice', 'automatizari', 'iluminat-exterior']],
  'zugrav': ['renovari-interioare', ['zugravit', 'vopsitorie']],
  'tamplar': ['renovari-interioare', ['tamplar', 'montaj-usi']],
  'constructor': ['constructii', ['zidarie', 'fundatii', 'consolidari']],
  'zidar': ['constructii', ['zidarie', 'fundatii']],
  'faiantar': ['renovari-interioare', ['gresie-faianta']],
  'montator': ['renovari-interioare', ['parchet', 'rigips', 'tavane-false']],
  'gradinar': ['exterioare', ['gradinarit', 'peisagistica', 'intretinere-gradina']],
  'curatenie': ['servicii-specializate', ['curatenie']],
  'specialist': ['servicii-specializate', ['proiectare', 'consultanta', 'verificare-termografica']],
  // ... poți adăuga mai multe mapări în funcție de nevoile tale
};

// Funcție helper pentru a găsi joburile potrivite pentru un trade
const getJobsForTrade = (trade) => {
  const mapping = tradesMapping[trade];
  if (!mapping) return null;
  
  const [category, subcategories] = mapping;
  return {
    category,
    subcategories
  };
};

module.exports = {
  serviceCategories,
  allTrades,
  tradesMapping,
  getJobsForTrade
};
